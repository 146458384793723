import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Payment.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";

import {
  numberWithCommas,
  getSearchParams,
  openNotification,
} from "../../utils/function";
import {
  apiTRSTGetTransaction,
  apiTRSTDel,
  apiSCBTransactionId,
} from "../../services/apis/transaction";
import {
  getPaymentSCB
} from "../../services/apis/report";
const { RangePicker } = DatePicker;
const { Option } = Select;

const PaymentSCB = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const perPage = 20;

  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [tableData, setTableData] = useState(undefined);
  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);
  const [waitingForDel, setWaitingForDel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [visibleTnxId, setVisibleTnxId] = useState(false);
  const [transactionlength, setransactionlength] = useState(0);

  useEffect(async () => {
    //getReport();
    setIsLoading(true);
    let date = {
      startDate: startDate,
      endDate: endDate
    }

    let transaction = await getPaymentSCB(date);
    if (transaction) {
      setIsLoading(false);
      setTableData(transaction.data);
      setransactionlength(transaction.data.length)
    }
    const dataArr = transaction.data;
    if (dataArr?.length > 0) {
      let dataNew = [];
      dataArr.map((item, index) => {
        if (
          index + 1 >= currentPage * perPage - perPage + 1 &&
          index + 1 <= currentPage * perPage
        ) {
          dataNew.push(item);
        }
      });
      setTableData(dataNew);
    }
  }, [currentPage, perPage]);

  const getReport = async () => {
    setIsLoading(true);
    let date = {
      startDate: startDate,
      endDate: endDate
    }

    let transaction = await getPaymentSCB(date);
    if (transaction) {
      setIsLoading(false);
      setTableData(transaction.data);
    }
  };

  // const paymentType = (paymentType) => {
  //   switch (paymentType) {
  //     case "Start Device":
  //       return "Start Device";
  //     case "Credit":
  //       return "Credit";
  //     default:
  //       return "-";
  //   }
  // };

  const selectDelete = [];
  const handleOnChange = (event) => {
    if (selectDelete.indexOf(event.transaction_id) > -1) {
      selectDelete.splice(selectDelete.indexOf(event.transaction_id), 1);
    } else {
      selectDelete.push(event.transaction_id);
    }
    console.log("Selected : " + selectDelete.length);
  };




  const columns = [
    {
      title: "",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, index) => (
        <div>
          <input
            type="checkbox"
            id={`select-${data.payeeproxyid}`}
            name="checkDel"
            onClick={() => handleOnChange(index)}
          />
        </div>
      ),
    },
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "data-time",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.converdateSCB}
        </div>
      ),
    },
    {
      title: "payee-name",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.payeename}
        </div>
      ),
    },
    {
      title: "payer-name",
      ellipsis: true,
      align: "payername",
      render: (data) => (
        <div>{data.payeraccountname || "-"}</div>
      ),
    },
    {
      title: "transactionid",
      ellipsis: true,
      render: (data) => <div>{data.transactionid || "-"}</div>,
    }, {
      title: "amount",
      ellipsis: true,
      render: (data) => <div>{data.amount || "-"}</div>,
    },
    {
      title: "Action type",
      ellipsis: true,
      render: (data) => <div>{data.type || "-"}</div>,
    },
    // {
    //   title: "ลูกค้า",
    //   ellipsis: true,
    //   className: "cs-pt",
    //   render: (data) => (
    //     <div
    //       className="cs-pt cl-g"
    //       onClick={() =>
    //         window.open(`/customer/list/${data.customer_id}`, "_blank")
    //       }
    //     >
    //       {data.customer_name || "-"}
    //     </div>
    //   ),
    // },
    // {
    //   title: "ช่องทาง",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) =>
    //     data.payment_type == "tw_pay" ? (
    //       <div
    //         onClick={() => getTransactionId(data?.transaction_id)}
    //         className="cs-pt cl-g"
    //       >
    //         TW Pay
    //       </div>
    //     ) : (
    //       <div>{paymentType(data.payment_type)}</div>
    //     ),
    // },
    // {
    //   title: "จำนวนเงิน",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => <div>{numberWithCommas(data.amount || 0, 2)}</div>,
    // },
    // {
    //   title: "สถานะ",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => <div>{data.status || "-"}</div>,
    // },
    // {
    //   title: "Action",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) =>
    //     menuList.includes("TRANSACMAN") && (
    //       <Tooltip placement="top" title="Delete">
    //         <div
    //           className="f-c-c d-b-t-n-s b-c-o-i"
    //           style={{ backgroundColor: "#ED2228" }}
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setModalId(data.transaction_id);
    //             setModalTitle(data.transaction_id);
    //             setVisible(true);
    //           }}
    //         >
    //           <i className="fas fa-trash"></i>
    //         </div>
    //       </Tooltip>
    //     ),
    // },
  ];
  const deleteSelected = async () => {
    setWaitingForDel(true);
    let admin_id = localStorage.getItem("user_id");
    let loop = 0;
    await selectDelete.forEach(async (value) => {
      const action = await apiTRSTDel(value, admin_id);
      loop = loop + 1;
      if (action && action.status) {
        console.log("Delete " + value + "this machine successful.");
      } else {
        openNotification("error", "Failed !", "Failed to delete this machine.");
      }

      if (loop >= selectDelete.length) {
        console.log("Load...");
        getReport();
      }
    });
    setWaitingForDel(false);
  };
  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let date = {
      startDate: startDate,
      endDate: endDate
    }

    let dataPayment = await getPaymentSCB(date);
    let csvData = [];
    console.log(dataPayment)
    if (dataPayment) {

      (dataPayment.data || []).map((data, idx) => {
        let resultRow = {};
        resultRow["payeeproxyid"] = data.payeeproxyid || "-";
        resultRow["payeeproxytype"] = data.payeeproxytype || "-";
        resultRow["payeeaccountnumber"] = data.payeeaccountnumber || "-";
        resultRow["payeename"] = data.payeename || "-";
        resultRow["payeraccountnumber"] = data.payeraccountnumber || "-";
        resultRow["payeraccountname"] = data.payeraccountname || "-";
        resultRow["payername"] = data.payername || "-";
        resultRow["sendingbankcode"] = data.sendingbankcode || "-";
        resultRow["receivingbankcode"] = data.receivingbankcode || "-";
        resultRow["amount"] = data.amount || "-";
        resultRow["transactionid"] = data.transactionid || "-";
        resultRow["billpaymentref1"] = data.billpaymentref1 || "-";
        resultRow["billpaymentref2"] = data.billpaymentref2 || "-";
        resultRow["billpaymentref3"] = data.billpaymentref3 || "-";
        resultRow["currencycode"] = data.currencycode || "-";
        resultRow["channelcode"] = data.channelcode || "-";
        resultRow["transactiontype"] = data.transactiontype || "-";
        resultRow["transactiondateandtime"] = data.transactiondateandtime || "-";
        resultRow["createdat"] = data.createdat || "-";
        resultRow["updatedat"] = data.updatedat || "-";
        resultRow["converdateSCB"] = data.converdateSCB || "-";
        resultRow["type"] = data.type || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "Payment-SCB";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Payment SCB</div>

      <div className="h-d-t-g-w-d-d-s">
        <div className="h-d-t-g-w-d-d-s-order-2">
          <Select disabled
            value={filterOption}
            style={{ width: "100%" }}
            onChange={(value) => {
              setCurrentPage(1);
              setFilterOption(value);
            }}
          >
            <Option value="">All Payment Type</Option>
            <Option value="Start Device">Start Device</Option>
            <Option value="Credit">Credit</Option>
          </Select>
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
          <RangePicker
            style={{ width: "100%" }}
            // format={(date) =>  moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div className="h-d-t-g-w-d-d-s-order-3">
          <Input disabled
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            setCurrentPage(1);
            getReport();
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.transaction_id}
        dataSource={(tableData) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={
            transactionlength
          }
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

    </div>
  );
};

export default PaymentSCB;
