import axios from "../../config/axios";
import axiostw from '../../config/axios-tw';
const apiDBGetReportSuperAdmin = async (start_date, end_date) => {
  try {
    const result = await axios.get(
      `/dashborad/report?start_date=${start_date}&end_date=${end_date}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetNewUser = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page
) => {
  try {
    const result = await axios.get(
      `/dashborad/new_user?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetMaximumUsage = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page
) => {
  try {
    const result = await axios.get(
      `/dashborad/maximum_usage?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetActiveUser = async (get_all, cur_page, per_page) => {
  try {
    const result = await axios.get(
      `/dashborad/active_user?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetInactiveUser = async (get_all, cur_page, per_page) => {
  try {
    const result = await axios.get(
      `/dashborad/top_losser?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetMachineAverage = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  filter
) => {
  try {
    const result = await axios.get(
      `/dashborad/machine_avg?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&filter=${filter}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetReportBranchAdmin = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard/site/usage?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetReportNewBranchAdmin = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard_new/site/usage?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetChartUsageBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard/site/usage_graph?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetChartUsageNewBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard_new/site/usage_graph?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetChartDayBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard/site/usage_day?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetChartDayNewBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard_new/site/usage_day?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetReportMachineBySiteId = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  site_id
) => {
  try {
    const result = await axios.get(
      `/dashboard/site/report_machine?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetReportGroupBySiteId = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  site_id
) => {
  try {
    const result = await axios.get(
      `/dashboard/site/report_group?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetUsageTimeBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard/site/usage_time?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetUsageTimeNewBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard_new/site/usage_time?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetShareBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard/site/share?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiDBGetUsageYearBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard/site/usage_year?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiDashboard_transation = async (data) => {
  try {
    const result = await axiostw.post(
      `/dashboard/Dashboard_transation`,data
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiDashboard_register = async (data) => {
  try {
    const result = await axiostw.post(
      `/dashboard/Dashboard_register`,data
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiDashboard_useractive = async (data) => {
  try {
    const result = await axiostw.post(
      `/dashboard/Dashboard_useractive`,data
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiDBGetUsageYearNewBySiteId = async (start_date, end_date, site_id) => {
  try {
    const result = await axios.get(
      `/dashboard_new/site/usage_year?start_date=${start_date}&end_date=${end_date}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiDashboard_site = async (data) => {
  try {
    const result = await axiostw.post(
      `/dashboard/Dashboard_site`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
export {
  apiDashboard_site,
  apiDBGetReportSuperAdmin,
  apiDBGetNewUser,
  apiDBGetMaximumUsage,
  apiDBGetActiveUser,
  apiDBGetInactiveUser,
  apiDBGetMachineAverage,
  apiDBGetReportBranchAdmin,
  apiDBGetChartUsageBySiteId,
  apiDBGetChartDayBySiteId,
  apiDBGetReportMachineBySiteId,
  apiDBGetReportGroupBySiteId,
  apiDBGetUsageTimeBySiteId,
  apiDBGetShareBySiteId,
  apiDBGetUsageYearBySiteId,
  apiDBGetReportNewBranchAdmin,
  apiDBGetChartUsageNewBySiteId,
  apiDBGetChartDayNewBySiteId,
  apiDBGetUsageTimeNewBySiteId,
  apiDBGetUsageYearNewBySiteId,
  apiDashboard_transation,
  apiDashboard_register,
  apiDashboard_useractive,
};
