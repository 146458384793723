import React, { useState, useEffect } from 'react'
import { InputNumber, Select, DatePicker, TimePicker } from 'antd';
import './CoinCollection.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiCGetMachineBySiteId, apiCPushCoin } from '../../services/apis/coin'
import { addTransaction } from "../../resolvers/Mutation";
import { useMutation } from "@apollo/client";
const { Option } = Select;
const { RangePicker } = DatePicker;


const CoinPush = () => {


    const [isLoading, setIsLoading] = useState(false)
    const [sites, setSites] = useState([])
    const [machines, setMachines] = useState([])
    const [coin, setCoin] = useState(undefined)
    const [addcoinTransaction, dataAddTransaction] = useMutation(addTransaction);
    console.log(dataAddTransaction)
    const [isAlertValue, setIsAlertValue] = useState(false)
    const [isAlertSite, setIsAlertSite] = useState(false)
    const [isAlertMachine, setIsAlertMachine] = useState(false)
    const [isAlertPeriod, setIsAlertPeriod] = useState(false)

    useEffect(() => {
        const getStaticDropdown = async () => {
            let site = await apiSGetSite()
            if (site && site.status) setSites(site.result || [])

        }
        getStaticDropdown()
    }, [])

    useEffect(() => {
        if (checkIfIdEqualToZero(coin && coin.site_id)) {
            const getMachine = async () => {
                let machine = await apiCGetMachineBySiteId(coin && coin.site_id)
                if (machine && machine.result) setMachines(machine.result || [])
                // console.log(machine)
            }
            getMachine()
        }
    }, [coin && coin.site_id])


    const addCoin = async () => {
        if (!(coin && coin.value) ||
            !(checkIfIdEqualToZero(coin && coin.site_id)) ||
            !(coin && coin.period) ||
            !(coin && coin.machine_id)
        ) {
            if (!(coin && coin.value)) setIsAlertValue(true)
            if (!(checkIfIdEqualToZero(coin && coin.site_id))) setIsAlertSite(true)
            if (!(coin && coin.machine_id)) setIsAlertMachine(true)
            if (!(coin && coin.period)) setIsAlertPeriod(true)

        } else {
            setIsLoading(true)



            let data = {
                machine_id: coin.machine_id,
                machine_no: coin.machine_no,
                customer_id: 1,
                action_by: "coin",
                amount: (coin.value).toString(),
                start_time: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                end_time: moment(coin.period[1]).format("YYYY-MM-DD HH:mm:ss"),
            }
            let variables = {
                "deviceName": coin.machine_no,
                "action_by": "coin",
                "run_value": (coin.value).toString(),
                "run_mode": "0",
                "run_time": "0",
                "customer_name": "By_admin",
                "time": moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                "device_startAt": moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                "device_stopAt": moment(coin.period[1]).format("YYYY-MM-DD HH:mm:ss"),
                "createAt": moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
            }
            data.admin_id = parseInt(localStorage.getItem('user_id'));
            console.log(data);
            console.log(variables);
            
            addcoinTransaction({
                variables: {
                    deviceName: coin.machine_no,
                    action_by: "coin",
                    run_value: (coin.value).toString(),
                    run_mode: "0",
                    run_time: "0",
                    customer_name: "By_admin",
                    time: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                    device_startAt: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                    device_stopAt: moment(coin.period[1]).format("YYYY-MM-DD HH:mm:ss"),
                    createAt: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                },
            });
            setIsLoading(false)
            openNotification('success', 'Success !', 'Add new coin successful.')
            //let newData = await apiCPushCoin(data)
            // console.log(newData)
            // if (newData && newData.status) {
            //     setIsLoading(false)
            //     openNotification('success', 'Success !', 'Add new coin successful.')
            //     setCoin(undefined)
            // } else {
            //     setIsLoading(false)
            //     openNotification('error', 'Failed !', 'Failed to add new coin.')
            // }
        }
    }




    return (
        <div>
            <div className="mg-bt-20 h-d-t-p">Add Coin</div>

            <div className="coin-collect-add-site-grid">

                <div className="f-s-c">Site</div>
                <div className="coin-collect-text-qr">
                    <div className={isAlertSite && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
                        <Select style={{ width: '100%' }}
                            placeholder="Site"
                            showSearch
                            value={coin && coin.site_id}
                            onChange={value => {
                                setIsAlertSite(false)
                                setCoin({
                                    ...coin,
                                    site_id: value,
                                    machine_id: undefined,
                                })
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
                        </Select>
                    </div>
                </div>

                <div className="f-s-c">Machine</div>
                <div className="coin-collect-text-qr">
                    <div className={isAlertMachine && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
                        <Select style={{ width: '100%' }}
                            placeholder="Machine"
                            disabled={!(checkIfIdEqualToZero(coin && coin.site_id))}
                            value={coin && coin.machine_id}
                            onChange={value => {
                                let datamachine = machines.find(({ machine_id }) => machine_id === value)

                                setIsAlertMachine(false)
                                setCoin({ ...coin, machine_id: datamachine.machine_id, machine_no: datamachine.machine_no })
                            }}
                        >
                            {machines.map((machine, idx) =>
                                <Option key={idx} value={machine.machine_id}>{machine.machine_name}</Option>
                            )}
                        </Select>
                    </div>
                </div>

                <div className="f-s-c">จำนวนเงิน</div>
                <div className="coin-collect-text-qr">
                    <div>
                        <InputNumber

                            value={coin && coin.value}
                            placeholder="จำนวนเงิน"
                            style={{ width: '100%', borderColor: isAlertValue ? '#EC1C24' : null }}
                            onChange={value => {
                                setIsAlertValue(false)
                                setCoin({ ...coin, value: value })
                            }}
                        />
                    </div>
                </div>

                <div className="f-s-c">Period</div>
                <div className="coin-collect-text-qr">
                    <RangePicker
                        style={{ width: '100%', borderColor: isAlertPeriod ? '#EC1C24' : null }}
                        showTime
                        placeholder={["Period Start", "Period End"]}
                        allowClear={false}
                        value={coin && coin.period ? coin.period : null}
                        // value={[
                        //     coin && coin.period && coin.period.start_date && moment(coin.period.start_date, 'YYYY-MM-DD HH:mm'),
                        //     coin && coin.period && coin.period.end_date && moment(coin.period.end_date, 'YYYY-MM-DD HH:mm')
                        // ]}
                        onChange={date => {

                            setIsAlertPeriod(false)
                            setCoin({ ...coin, period: date })

                            // let newCoin = { ...coin }
                            // if (!newCoin.period) newCoin.period = {}

                            // newCoin.period.start_date = moment(date[0]).format('YYYY-MM-DD HH:mm')
                            // newCoin.period.end_date = moment(date[1]).format('YYYY-MM-DD HH:mm')
                            // setCoin({ ...coin, period: newCoin })
                        }}
                    />
                </div>


            </div>




            <div className="f-c-c s-e-b">
                {/* <div className="f-c-c d-b-t-n-s d-bg-c-t"
                    style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
                    onClick={() => addCoin()}
                >{isLoading ? <LoadingOutlined /> : 'Submit'}</div> */}
            </div>

        </div>
    )
}

export default CoinPush
