import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import "./Navigation.css";

import { logOut } from "../../utils/function";
import { changehideNav } from "../../redux/data";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);

  const menuList = (localStorage.getItem("list") || "").split(",");
  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [currentPath, setCurrentPath] = useState("dashboard");
  const [checkPathName, setCheckPathName] = useState("dashboard");

  const [navLists, setNavLists] = useState([
    {
      name: "Dashboard",
      role: ["SUPERADMIN", "BRANCHADMIN"],
      path: "dashboard",
      version: 1,
      icon: <i className="bi bi-bar-chart-line"></i>,
      display: menuList.includes("DASHBOARD") || role === "BRANCHADMIN",
      dropdown: false,
      children: [],
    },
    {
      name: "Dashboard Customer",
      role: ["SUPERADMIN"],
      path: "dashboardCustomer",
      version: 1,
      icon: <i className="bi bi-bar-chart-line"></i>,
      display: menuList.includes("DASHBOARD") || role === "BRANCHADMIN",
      dropdown: false,
      children: [],
    },
    {
      name: "Transaction",
      role: ["SUPERADMIN"],
      path: "transaction",
      version: 1,
      icon: <i className="bi bi-file-earmark-text-fill"></i>,
      display: menuList.includes("TRANSACTION"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list",
          display: menuList.includes("TRANSACTION"),
        },
        {
          name: "Reconcile",
          role: ["SUPERADMIN"],
          path: "reconcile",
          display: menuList.includes("TRANSACTION"),
        },
      ],
    },
    {
      name: "Transaction V2",
      role: ["SUPERADMIN"],
      path: "transaction",
      version: 2,
      icon: <i className="bi bi-file-earmark-text-fill"></i>,
      display: menuList.includes("TRANSACTION"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list/v2",
          display: menuList.includes("TRANSACTION"),
        },
        {
          name: "TransactionCenter",
          role: ["SUPERADMIN"],
          path: "TransactionCenter/list",
          display: menuList.includes("TRANSACTION"),
        },
        {
          name: "CheckTransactionCenter",
          role: ["SUPERADMIN"],
          path: "checktransaton/list",
          display: menuList.includes("TRANSACTION"),
        },
        // {
        //   name: "Reconcile",
        //   role: ["SUPERADMIN_V2"],
        //   path: "reconcile",
        //   display: menuList.includes("TRANSACTION"),
        // },
      ],
    },
    {
      name: "Payment",
      role: ["SUPERADMIN"],
      path: "payment",
      version: 1,
      icon: <i className="bi bi-cash-stack"></i>,
      display: menuList.includes("PAYMENT"),
      dropdown: false,
      children: [],
    },
    {
      name: "Payment-Scb",
      role: ["SUPERADMIN"],
      path: "reportSCB",
      version: 1,
      icon: <i className="bi bi-cash-stack"></i>,
      display: menuList.includes("PAYMENT"),
      dropdown: false,
      children: [],
    },
    // {
    //   name: 'Slip',
    //   role: ['SUPERADMIN', 'BRANCHADMIN'],
    //   path: 'slip',
    //   icon: <i className="bi bi-file-earmark-arrow-up"></i>,
    //   display: menuList.includes('SLIP'),
    //   dropdown: false,
    //   children: [],
    // },
    {
      name: "Machine",
      role: ["SUPERADMIN", "BRANCHADMIN"],
      path: "machine",
      version: 1,
      icon: <i className="bi bi-gear"></i>,
      display:
        menuList.includes("MACHINEVIEW") ||
        menuList.includes("MACHINEMAN") ||
        role === "BRANCHADMIN",
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN", "BRANCHADMIN"],
          path: "list",
          display:
            menuList.includes("MACHINEVIEW") ||
            menuList.includes("MACHINEMAN") ||
            role === "BRANCHADMIN",
        },
        {
          name: "Machine Set Site",
          role: ["SUPERADMIN", "BRANCHADMIN"],
          path: "All",
          display:
            menuList.includes("MACHINEVIEW") ||
            menuList.includes("MACHINEMAN") ||
            role === "BRANCHADMIN",
        },
        {
          name: "Add",
          role: ["SUPERADMIN"],
          path: "add",
          display: menuList.includes("MACHINEMAN"),
        },
        {
          name: "Type",
          role: ["SUPERADMIN"],
          path: "type",
          display: menuList.includes("MACHINEMAN"),
        },
        {
          name: "Controller",
          role: ["SUPERADMIN"],
          path: "controller",
          display: menuList.includes("MACHINEMAN"),
        },
      ],
    },
    {
      name: "Machine V2",
      role: ["SUPERADMIN"],
      path: "machine",
      version: 2,
      icon: <i className="bi bi-gear"></i>,
      display:
        menuList.includes("MACHINEVIEW") || menuList.includes("MACHINEMAN"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list/v2",
          display:
            menuList.includes("MACHINEVIEW") || menuList.includes("MACHINEMAN"),
        },
        {
          name: "Warehouse",
          role: ["SUPERADMIN"],
          path: "list/MachineListWarehousev2",
          display:
            menuList.includes("MACHINEVIEW") || menuList.includes("MACHINEMAN"),
        },
        {
          name: "Add",
          role: ["SUPERADMIN"],
          path: "add/v2",
          display: menuList.includes("MACHINEMAN"),
        },
      ],
    },
    {
      name: "Customer",
      role: ["SUPERADMIN"],
      path: "customer",
      version: 1,
      icon: <i className="bi bi-people-fill"></i>,
      display:
        menuList.includes("CUSTOMERVIEW") || menuList.includes("CUSTOMERMAN"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list",
          display:
            menuList.includes("CUSTOMERVIEW") ||
            menuList.includes("CUSTOMERMAN"),
        },
        {
          name: "Add",
          role: ["SUPERADMIN"],
          path: "add",
          display: menuList.includes("CUSTOMERMAN"),
        },
      ],
    },
    // {
    //   name: "Site Management",
    //   role: ["SUPERADMIN"],
    //   path: "site-management",
    //   version: 1,
    //   icon: <i className="bi bi-menu-button-wide-fill"></i>,
    //   display: menuList.includes("SITEVIEW") || menuList.includes("SITEMAN"),
    //   dropdown: false,
    //   children: [
    //     {
    //       name: "List",
    //       role: ["SUPERADMIN"],
    //       path: "list",
    //       display:
    //         menuList.includes("SITEVIEW") || menuList.includes("SITEMAN"),
    //     },
    //     // {
    //     //   name: "Add",
    //     //   role: ["SUPERADMIN"],
    //     //   path: "add",
    //     //   display: menuList.includes("SITEMAN"),
    //     // },
    //   ],
    // },
    {
      name: "Site Management V2",
      role: ["SUPERADMIN"],
      path: "site-management",
      version: 2,
      icon: <i className="bi bi-menu-button-wide-fill"></i>,
      display: menuList.includes("SITEVIEW") || menuList.includes("SITEMAN"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list/v2",
          display:
            menuList.includes("SITEVIEW") || menuList.includes("SITEMAN"),
        },
        {
          name: "Add",
          role: ["SUPERADMIN"],
          path: "add/v2",
          display: menuList.includes("SITEMAN"),
        },
      ],
    },
    {
      name: "Admin Management",
      role: ["SUPERADMIN"],
      path: "admin-management",
      version: 1,
      icon: <i className="bi bi-person-square"></i>,
      display:
        menuList.includes("ADMINVIEW") ||
        menuList.includes("ADMINMAN") ||
        menuList.includes("SUPERADMINVIEW") ||
        menuList.includes("SUPERADMINMAN"),
      dropdown: false,
      children: [
        {
          name: "List Admin",
          role: ["SUPERADMIN"],
          path: "list-admin",
          display:
            menuList.includes("ADMINVIEW") || menuList.includes("ADMINMAN"),
        },
        {
          name: "Add Admin",
          role: ["SUPERADMIN"],
          path: "add-admin",
          display: menuList.includes("ADMINMAN"),
        },
        {
          name: "List Superadmin",
          role: ["SUPERADMIN"],
          path: "list-superadmin",
          display:
            menuList.includes("SUPERADMINVIEW") ||
            menuList.includes("SUPERADMINMAN"),
        },
        {
          name: "Add Superadmin",
          role: ["SUPERADMIN"],
          path: "add-superadmin",
          display: menuList.includes("SUPERADMINMAN"),
        },
      ],
    },
    {
      name: "Admin Management V2",
      role: ["SUPERADMIN"],
      path: "admin-management",
      version: 2,
      icon: <i className="bi bi-person-square"></i>,
      display:
        menuList.includes("ADMINVIEW") ||
        menuList.includes("ADMINMAN") ||
        menuList.includes("SUPERADMINVIEW") ||
        menuList.includes("SUPERADMINMAN"),
      dropdown: false,
      children: [
        {
          name: "List Admin",
          role: ["SUPERADMIN"],
          path: "list/v2",
          display:
            menuList.includes("ADMINVIEW") || menuList.includes("ADMINMAN"),
        },
        {
          name: "Add Admin",
          role: ["SUPERADMIN"],
          path: "add/v2",
          display: menuList.includes("ADMINMAN"),
        },
      ],
    },
    {
      name: "Banner",
      role: ["SUPERADMIN"],
      path: "banner",
      version: 1,
      icon: <i className="fas fa-scroll"></i>,
      display: menuList.includes("BANNER"),
      dropdown: false,
      children: [
        {
          name: "Home Banner",
          role: ["SUPERADMIN"],
          path: "home",
          display: menuList.includes("BANNER"),
        },
        {
          name: "Site Banner",
          role: ["SUPERADMIN"],
          path: "site",
          display: menuList.includes("BANNER"),
        },
      ],
    },
    {
      name: "News",
      role: ["SUPERADMIN"],
      path: "news",
      version: 1,
      icon: <i className="fas fa-newspaper"></i>,
      display: menuList.includes("ANNOUNCE"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list",
          display: menuList.includes("ANNOUNCE"),
        },
        {
          name: "Add",
          role: ["SUPERADMIN"],
          path: "add",
          display: menuList.includes("ANNOUNCE"),
        },
      ],
    },
    {
      name: "Coin Collection",
      role: ["SUPERADMIN"],
      path: "coin",
      version: 1,
      icon: <i className="fas fa-coins"></i>,
      display: menuList.includes("COINCOLLECT"),
      dropdown: false,
      children: [
        {
          name: "History",
          role: ["SUPERADMIN"],
          path: "history",
          display: menuList.includes("COINCOLLECT"),
        },
        {
          name: "Coin Reconcile",
          role: ["SUPERADMIN"],
          path: "reconcile",
          display: menuList.includes("COINRECONCILE"),
        },
        {
          name: "Box",
          role: ["SUPERADMIN"],
          path: "box",
          display: menuList.includes("COINCOLLECT"),
        },
        {
          name: "Add Coin",
          role: ["SUPERADMIN"],
          path: "add",
          display: menuList.includes("COINMAN"),
        },
      ],
    },
    {
      name: "Maintenance",
      role: ["SUPERADMIN"],
      path: "maintenance",
      version: 1,
      icon: <i className="fas fa-tools"></i>,
      display: menuList.includes("MANTENANCE"),
      dropdown: false,
      children: [
        {
          name: "Support",
          role: ["SUPERADMIN"],
          path: "support",
          display: menuList.includes("MANTENANCE"),
        },
        {
          name: "Ticket",
          role: ["SUPERADMIN"],
          path: "ticket",
          display: menuList.includes("MANTENANCE"),
        },
        {
          name: "Create Ticket",
          role: ["SUPERADMIN"],
          path: "create-ticket",
          display: menuList.includes("MANTENANCE"),
        },
        {
          name: "History",
          role: ["SUPERADMIN"],
          path: "history",
          display: menuList.includes("MANTENANCE"),
        },
      ],
    },
    {
      name: "Call Support",
      role: ["SUPERADMIN"],
      path: "call-support",
      version: 1,
      icon: <i className="fas fa-headset"></i>,
      display: menuList.includes("CALLSUPPORT"),
      dropdown: false,
      children: [
        {
          name: "List Maintenance",
          role: ["SUPERADMIN"],
          path: "list-maintenance",
          display: true,
        },
        {
          name: "Add Maintenance",
          role: ["SUPERADMIN"],
          path: "add-maintenance",
          display: true,
        },
        {
          name: "List Inquiry",
          role: ["SUPERADMIN"],
          path: "list-inquiry",
          display: true,
        },
        {
          name: "Add Inquiry",
          role: ["SUPERADMIN"],
          path: "add-inquiry",
          display: true,
        },
      ],
    },
    {
      name: "Broadcast",
      role: ["SUPERADMIN"],
      path: "broadcast",
      version: 1,
      icon: <i className="fas fa-broadcast-tower"></i>,
      display: menuList.includes("BOARDCAST"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list",
          display: true,
        },
        {
          name: "Add",
          role: ["SUPERADMIN"],
          path: "add",
          display: true,
        },
      ],
    },
    {
      name: "Site Promotion",
      role: ["SUPERADMIN"],
      path: "site-promotion",
      version: 1,
      icon: <i className="fas fa-building"></i>,
      display: menuList.includes("SITEPROMO"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "Discount",
          role: ["SUPERADMIN"],
          path: "discount",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "Free",
          role: ["SUPERADMIN"],
          path: "free",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "Time Count",
          role: ["SUPERADMIN"],
          path: "time-count",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "Point",
          role: ["SUPERADMIN"],
          path: "point",
          display: menuList.includes("SITEPROMO"),
        },{
          name: "Collect",
          role: ["SUPERADMIN"],
          path: "Collect",
          display: menuList.includes("SITEPROMO"),
        },{
          name: "ListCollect",
          role: ["SUPERADMIN"],
          path: "ListCollect",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "ManageCollect",
          role: ["SUPERADMIN"],
          path: "manamentCollect",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "CreatePromocode",
          role: ["SUPERADMIN"],
          path: "CreatePromocode",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "listPromocode",
          role: ["SUPERADMIN"],
          path: "listPromocode",
          display: menuList.includes("SITEPROMO"),
        }
      ],
    },
    {
      name: "Site Promotion V2",
      role: ["SUPERADMIN"],
      path: "site-promotion",
      version: 2,
      icon: <i className="fas fa-building"></i>,
      display: menuList.includes("SITEPROMO"),
      dropdown: false,
      children: [
        {
          name: "List",
          role: ["SUPERADMIN"],
          path: "list/v2",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "Discount",
          role: ["SUPERADMIN"],
          path: "discount/v2",
          display: menuList.includes("SITEPROMO"),
        },
        {
          name: "Free",
          role: ["SUPERADMIN"],
          path: "free/v2",
          display: menuList.includes("SITEPROMO"),
        },
        // {
        //   name: "Time Count",
        //   role: ["SUPERADMIN"],
        //   path: "time-count/v2",
        //   display: menuList.includes("SITEPROMO"),
        // },
        // {
        //   name: "Point",
        //   role: ["SUPERADMIN"],
        //   path: "point/v2",
        //   display: menuList.includes("SITEPROMO"),
        // },
      ],
    },
    {
      name: "Promotion",
      role: ["SUPERADMIN"],
      path: "promotion",
      version: 1,
      icon: <i className="fas fa-percentage"></i>,
      display: menuList.includes("ALLPROMO"),
      dropdown: false,
      children: [
        {
          name: "Topup",
          role: ["SUPERADMIN"],
          path: "topup",
          display: menuList.includes("ALLPROMO"),
        },
        {
          name: "Time Count",
          role: ["SUPERADMIN"],
          path: "time-count",
          display: menuList.includes("ALLPROMO"),
        },
        {
          name: "Reward",
          role: ["SUPERADMIN"],
          path: "reward",
          display: menuList.includes("ALLPROMO"),
        },
        {
          name: "Point",
          role: ["SUPERADMIN"],
          path: "point",
          display: menuList.includes("ALLPROMO"),
        },
        {
          name: "Friend",
          role: ["SUPERADMIN"],
          path: "friend",
          display: menuList.includes("ALLPROMO"),
        },
        {
          name: "Promotion Code",
          role: ["SUPERADMIN"],
          path: "promotion-code",
          display: menuList.includes("ALLPROMO"),
        },
        {
          name: "Redeem",
          role: ["SUPERADMIN"],
          path: "redeem",
          display: menuList.includes("ALLPROMO"),
        },
      ],
    },
    {
      name: "Washer",
      role: ["SUPERADMIN"],
      path: "washer",
      version: 1,
      icon: <i class="bi bi-bag-fill"></i>,
      display: menuList.includes("WASHER"),
      dropdown: false,
      children: [
        {
          name: "Transaction List",
          role: ["SUPERADMIN"],
          path: "transaction",
          display: menuList.includes("WASHER"),
        },
        {
          name: "Pending Task",
          role: ["SUPERADMIN"],
          path: "task",
          display: menuList.includes("WASHER"),
        },
        {
          name: "Wahser",
          role: ["SUPERADMIN"],
          path: "user",
          display: menuList.includes("WASHER"),
        },
        {
          name: "Coupon",
          role: ["SUPERADMIN"],
          path: "coupon",
          display: menuList.includes("WASHER"),
        },
        {
          name: "Bag",
          role: ["SUPERADMIN"],
          path: "bag",
          display: menuList.includes("WASHER"),
        },
        {
          name: "Banner",
          role: ["SUPERADMIN"],
          path: "banner",
          display: menuList.includes("WASHER"),
        },
        {
          name: "News",
          role: ["SUPERADMIN"],
          path: "news",
          display: menuList.includes("WASHER"),
        },
      ],
    },
    {
      name: "Report",
      role: ["BRANCHADMIN"],
      path: "report",
      version: 1,
      icon: <i className="bi bi-file-earmark-ruled-fill"></i>,
      display: true,
      dropdown: false,
      children: [],
    },
    {
      name: "Report-Promocode",
      role: ["BRANCHADMIN"],
      path: "ReportPromotion",
      version: 1,
      icon: <i className="bi bi-file-earmark-ruled-fill"></i>,
      display: true,
      dropdown: false,
      children: [],
    }, {
      name: "Report-Collect",
      role: ["BRANCHADMIN"],
      path: "ReportCollect",
      version: 1,
      icon: <i className="bi bi-file-earmark-ruled-fill"></i>,
      display: true,
      dropdown: false,
      children: [],
    },
    
  ]);

  const [responsiveNav, setResponsiveNav] = useState(window.innerWidth <= 1024);
  const [navWidth, setNavWidth] = useState(
    window.innerWidth <= 1024 ? "60px" : null
  );
  const [hideNav, setHideNav] = useState(Boolean(localStorage.getItem("hnv")));

  useEffect(() => {
    let currentPath = location.pathname.split("/");
    setCurrentPath(currentPath);
    if (currentPath[2]) {
      let newNavLists = [...navLists];
      let index = newNavLists.findIndex((nav) =>
        currentPath[3]
          ? currentPath[3] == `v${nav.version}` && nav.path === currentPath[1]
          : nav.path === currentPath[1]
      );
      if (index >= 0) {
        newNavLists[index].dropdown = true;
        setNavLists(newNavLists);
      }
    }
    // return () => { }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => setResponsiveNav(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setHideNav(data.hideNav);
  }, [data.hideNav]);

 // console.log(localStorage.getItem("user_role").toUpperCase());

  return (
    <div>
      {!responsiveNav && (
        <div className="navigation-menu-close-container">
          <div
            className={`f-c-c navigation-menu-close navigation-menu-close-${hideNav} navigation-container-transition`}
            onClick={() => dispatch(changehideNav())}
          >
            {hideNav ? (
              <i className="fas fa-angle-right"></i>
            ) : (
              <i className="fas fa-angle-left"></i>
            )}
          </div>
        </div>
      )}
      <div
        className={`navigation-container-${isMobile} ${
          !responsiveNav ? `navigation-container-width-${hideNav}` : ""
        } navigation-container-transition`}
        style={{ width: isMobile && responsiveNav ? navWidth : null }}
        // use for : when click menu on mobile view >> auto close nav
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isMobile && responsiveNav && navWidth === "60px")
            setNavWidth("300px");
        }}
        // use for : when click menu on mobile view >> auto close nav
        onMouseLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isMobile && responsiveNav && navWidth === "300px")
            setNavWidth("60px");
        }}
      >
        <div
          className="navigation-inside-container"
          style={{
            pointerEvents:
              isMobile && responsiveNav && navWidth === "60px" ? "none" : null,
          }}
        >
          <div className="navigation-menu-grid navigation-menu-logo">
            <div className="f-c-c navigation-menu-image">
              <img src="/assets/image/logo/logo_color_square.png" alt="logo" />
            </div>
            <div className="f-s-c">Trendy Wash Admin Panel</div>
          </div>

          <hr />

          {navLists
            .filter((list) => list.display)
            .filter((list) =>
              list.role.includes(
                localStorage.getItem("user_role").toUpperCase()
              )
            )
            .map((list, idx) =>
              list.children.length <= 0 ? (
                <Link
                  key={idx}
                  to={`/${list.path}`}
                  // use for : when click menu on mobile view >> auto close nav
                  onClick={() => {
                    if (isMobile && responsiveNav && navWidth === "300px")
                      setNavWidth("60px");
                    else setNavWidth(null);
                    setCheckPathName(
                      list?.version == 2
                        ? ` ${list.path}/v${list?.version}`
                        : list.path
                    );
                  }}
                >
                  <div
                    className={`navigation-menu-grid ${
                      (currentPath[1] === list.path && "cl-wh") || ""
                    }`}
                  >
                    <div className="f-c-c navigation-menu-icon">
                      {list.icon}
                    </div>
                    <div className="f-s-c">{list.name}</div>
                  </div>
                </Link>
              ) : (
                <div key={idx}>
                  <div
                    className={`navigation-menu-grid 
                    ${
                      currentPath?.length == 4 &&
                      list?.version == 2 &&
                      currentPath[1] === list.path
                        ? "cl-wh"
                        : currentPath?.length == 3 &&
                          list?.version == 1 &&
                          checkPathName === list.path
                        ? "cl-wh"
                        : ""
                    }
                    `}
                    onClick={() => {
                      let updateNav = [...navLists];
                      let idxList = updateNav.findIndex(
                        (element) => element.name === list.name
                      );

                      updateNav[idxList].dropdown =
                        !updateNav[idxList].dropdown;
                      setNavLists(updateNav);
                      setCheckPathName(
                        list?.version == 2
                          ? ` ${list.path}/v${list?.version}`
                          : list.path
                      );
                    }}
                  >
                    <div className="f-c-c navigation-menu-icon">
                      {list.icon}
                    </div>
                    <div className="f-s-c">{list.name}</div>
                    <div
                      className={`f-c-c navigation-menu-arrow ${
                        list.dropdown && "navigation-menu-arrow-bottom"
                      }`}
                    >
                      <i className="bi bi-caret-right-fill"></i>
                    </div>
                  </div>
                  <div
                    className={`navigation-menu-children-container ${
                      !list.dropdown && "h-g-0"
                    }`}
                  >
                    {list.children
                      .filter((list) => list.display)
                      .filter((list) =>
                        list.role.includes(
                          localStorage.getItem("user_role").toUpperCase()
                        )
                      )
                      .map((child, idxChild) => (
                        <Link
                          key={idxChild}
                          to={`/${list.path}/${child.path}`}
                          // use for : when click menu on mobile view >> auto close nav
                          onClick={() => {
                            if (
                              isMobile &&
                              responsiveNav &&
                              navWidth === "300px"
                            )
                              setNavWidth("60px");
                            else setNavWidth(null);
                            setCheckPathName(
                              list?.version == 2
                                ? ` ${list.path}/v${list?.version}`
                                : list.path
                            );
                          }}
                        >
                          <div
                            className={`navigation-menu-children-grid ${
                              currentPath[1] === list.path &&
                              currentPath?.length == 4 &&
                              `${currentPath[2]}/${currentPath[3]}` ==
                                child.path
                                ? "cl-wh"
                                : currentPath?.length == 3 &&
                                  currentPath[1] === list.path &&
                                  currentPath[2] === child.path
                                ? "cl-wh"
                                : ""
                            }`}
                          >
                            <div></div>
                            <div className="f-s-c">{child.name}</div>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              )
            )}

          <div
            className="navigation-menu-grid"
            onClick={() => {
              logOut();
              window.location.href = "/sign-in";
            }}
          >
            <div className="f-c-c navigation-menu-icon">
              <i className="bi bi-box-arrow-left"></i>
            </div>
            <div className="f-s-c">Log Out</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
